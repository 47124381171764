import React, { createContext, useState } from 'react';

type Identifier =
  | 'authenticating'
  | 'dashboard'
  | 'project'
  | 'segments'
  | 'media'
  | 'rename'
  | 'save'
  | 'exporting'
  | 'addAnnotation'
  | 'amendAnnotation'
  | 'amendReaction'
  | 'projectCreate'
  | 'changePermission'
  | 'addEmailPermission'
  | 'amendEmailPermission'
  | 'importing'
  | 'importingMedia'
  | 'loadingProjects'
  | 'loadingDirectoriesWithProjects'
  | 'createMediaFolder'
  | 'amendMedia'
  | 'loadingPlan'
  | 'loadingSubscribePlan'
  | 'loadingUpdatePlan'
  | 'loadingLinkCustomerPortal'
  | 'saveTranscript';

type StackElement = {
  identifier: string;
  estimatedPercent: number;
};
const initialState = {
  stack: [] as StackElement[],
  pushProgress: (arg0: Identifier) => {},
  completeProgress: (arg0: Identifier) => {},
  incrementProgress: (arg0: number) => {},
  isComplete: () => {},
};
const progressBarStore = createContext(initialState);
const { Provider } = progressBarStore;

type ProgressBarProviderProps = {
  children: any;
};

const ProgressBarProvider = ({ children }: ProgressBarProviderProps) => {
  const [stack, setStack] = useState<StackElement[]>([]);
  const pushProgress = (identifier: Identifier) => {
    setStack(stack => {
      const i = stack.findIndex(s => s.identifier === identifier);
      if (i > -1) {
        let update = stack[i];
        update.estimatedPercent = 0;
        return [...stack.slice(0, i), update, ...stack.slice(i + 1)];
      }
      return stack.concat({ identifier, estimatedPercent: 0 });
    });
  };
  // const updateProgress = (identifier: string, percent: number) => {
  //   const i = stack.findIndex(s => s.identifier === identifier)
  //   if (i > -1) {
  //     let update = stack[i]
  //     update.estimatedPercent = percent;
  //     setStack([...stack.slice(0, i), update, ...stack.slice(i)]);
  //   }
  // }
  const incrementProgress = (amount: number) => {
    let changemade = false;
    const newStack = stack.map(({ identifier, estimatedPercent }) => {
      if (estimatedPercent !== 100) {
        let newestimatedPercent = estimatedPercent + amount;
        if (newestimatedPercent > 95) {
          newestimatedPercent = 95;
        }
        if (newestimatedPercent !== estimatedPercent) {
          estimatedPercent = newestimatedPercent;
          changemade = true;
        }
      }
      return { identifier, estimatedPercent };
    });
    if (changemade) setStack(newStack);
  };
  const completeProgress = (identifier: Identifier) => {
    const i = stack.findIndex(s => s.identifier === identifier);
    let tempStack = stack;
    if (i > -1) {
      let update = stack[i];
      update.estimatedPercent = 100;
      tempStack = [...stack.slice(0, i), update, ...stack.slice(i + 1)];
    }
    //once they're all at 100%, we can clear them
    const completed = tempStack.filter(({ estimatedPercent }) => estimatedPercent >= 100);
    if (completed.length === stack.length) tempStack = [];
    setStack(tempStack);
  };
  const isComplete = () => !stack.length;

  return (
    <Provider value={{ stack, pushProgress, completeProgress, incrementProgress, isComplete }}>{children}</Provider>
  );
};

export { progressBarStore, ProgressBarProvider };
