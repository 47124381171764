import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import {
  CHARGE_CREDIT_MEDIA_BEFORE,
  CHARGE_EXTRA_CREDIT,
  CREATE_MEDIA,
  DELETE_FILE_UPLOAD,
  GET_SIGNED_URL,
  GET_STATUS_TRANSCRIBE,
  IMPORT_FILE_TO_PROJECT,
  RETRIVED_TRANSCRIBE,
  TRANSCRIBE_MEDIA,
  TRANSLATE_MEDIA,
  GET_ASSEMBLY_PROJECT,
  UPDATE_STATUS_MEDIA_FAIL
} from '../queries';
import moment from 'moment';

const initialState = {
  lastTranscibe: {} as LanguageRegion,
  listFileImport: [] as ImportedMedia[],
  setProjectId: (arg0: string) => { },
  handleLoadFiles: (arg0: FileCloud[]) => { },
  setAccessTokenGD: (arg0: string) => { },
  onSubUploadFile: (arg0: PercentageProcessData) => { },
  onSubTranslateFile: (arg0: ProgressTranslateResponse) => { },
  abortUploadFile: (arg0: ImportedMedia) => { },
  onStartTranscribe: (arg0: ImportedMedia, currentLanguageRegion?: LanguageRegion, dictionaryId?: string) => { },
  onResultTranscribe: (arg0: JobTranscribe) => { },
  setListFileMedia: (arg0: ImportedMedia[]) => { },
  onStartTranslate: (arg0: ImportedMedia, listTranslate: Language[]) => { },
  onTranscribeDone: (arg0: ImportedMedia) => { },
  onChargeCreditBefore: (media: ImportedMedia, languages: string[], type: string) => { },
  onCloseModalInsufficientCredit: (arg0: ImportedMedia) => { },
  setLastTranscribe: (arg0: LanguageRegion) => { },
  onAmendMedia: (arg0: string, arg1: string | null) => { },
};
const sideBarStore = createContext(initialState);
const { Provider } = sideBarStore;

type SideBarProviderProps = {
  children: any;
};

enum InputFileEnum {
  LOCAL = "LOCAL",
  DROPBOX = "DROPBOX",
  DRIVE = "DRIVE",
}

enum TranscribeStatus {
  TRANSCRIBED = "transcribed",
  TRANSCRIBING = "transcribing",
  REJECTED = "rejected",
  FAILED = "failed",
  TRANSLATED = "translated",
  TRANSLATING = "translating",
  READY = "ready",
  TRANSCRIBE_PAID = "transcribe_paid",
  TRANSLATE_PAID = "translate_paid",
  UPLOADING = "uploading",
  TRANSCODING = "transcoding"
}

const SideBarProvider = ({ children }: SideBarProviderProps) => {
  const [listFileImport, setListFileImport] = useState<ImportedMedia[]>([]);
  const [projectId, setProjectId] = useState<string>('');
  const [accessTokenGD, setAccessTokenGD] = useState('');
  const [lastTranscibe, setLastTranscribe] = useState<LanguageRegion>({});
  const [countTranslate, setCountTranslate] = useState(0);

  const [getAssemblyProject] = useLazyQuery<
    AssemblyProjectData,
    AssemblyProjectVars
  >(GET_ASSEMBLY_PROJECT, {
    variables: { projectId: projectId || "" },
    fetchPolicy: "cache-and-network",
  });

  const [createMedia] =
    useMutation<MediaDataResponse, { filename: string, frameFilePath: string, projectId?: string }>(CREATE_MEDIA, {
      onCompleted: (data: MediaDataResponse) => {
        console.log('createMedia', data.createMedia);

        const media = data.createMedia;
        const idx = listFileImport.findIndex(item => item.name === media.name && item.status === TranscribeStatus.UPLOADING && !item.archived);

        const currentFileImport = listFileImport[idx];
        if (!currentFileImport || !currentFileImport.fileCloud) return;

        updateMediaAtIdx(idx, media);
        const isLocalFile = currentFileImport.fileCloud.type === InputFileEnum.LOCAL;
        const isDriveFile = currentFileImport.fileCloud.type === InputFileEnum.DRIVE;

        if (isLocalFile) {
          getSignedUrl({
            variables: {
              filename: currentFileImport.name,
              mimeType: currentFileImport.fileCloud.file.type,
              mediaId: media.id,
            }
          });

          return;
        }

        importFileToProject({
          variables: {
            mediaId: media.id,
            fileName: currentFileImport.name,
            type: currentFileImport.fileCloud.type,
            fileSize: currentFileImport.fileCloud.fileSize,
            linkToFile: currentFileImport.fileCloud.linkToFile,
            ...(isDriveFile ? { accessToken: accessTokenGD } : {}),
          }
        });
      }
    });

  const [getSignedUrl] =
    useMutation<SignUrlDataResponse, { filename: string, mimeType: string, mediaId?: string }>(GET_SIGNED_URL, {
      onCompleted: (data: SignUrlDataResponse) => {
        const response = data.createSignUrlAws;
        const mediaId = response.mediaId;

        const currentFileImport = listFileImport.find(item => item.id === response.mediaId)
        if (!currentFileImport || !currentFileImport.fileCloud) return;

        const blob = new Blob([currentFileImport.fileCloud.file], { type: currentFileImport.fileCloud.file.type })
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', response.url, true);
        xhr.setRequestHeader('Content-Type', blob.type);

        function onProgress(event: any) {
          const percentage = (event.loaded / event.total) * 50;
          updateMediaByMediaId(mediaId, { percentage });
        }

        xhr.upload.addEventListener('progress', onProgress, false);
        xhr.addEventListener("abort", () => { console.log('abort', currentFileImport.name) }, false);

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            console.log("UPLOAD SUCCESS", currentFileImport.name);

            importFileToProject({
              variables: {
                mediaId: response.mediaId,
                fileName: response.key,
                type: InputFileEnum.LOCAL,
                durationInSeconds: currentFileImport.fileCloud?.durationInSeconds,
                fileSize: currentFileImport.fileCloud?.fileSize,
              }
            })
          }
        };

        updateMediaByMediaId(mediaId, { percentage: 0, xhr });
        xhr.onerror = function () {
          updateMediaByMediaId(mediaId, { status: TranscribeStatus.FAILED });
        };
        xhr.send(blob);
      }
    });

  const [
    importFileToProject,
  ] = useMutation<ImportFileToProjectResponse, FileCloud>(IMPORT_FILE_TO_PROJECT, {
    onCompleted: (data: ImportFileToProjectResponse) => {
      console.log('importFileToProject', data.uploadImportMediaIntoProject);
      const media = data.uploadImportMediaIntoProject;
      updateMediaByMediaId(media.id, {
        status: media.status,
        percentage: 51,
        progressText: 'Transcoding'
      });
    }
  });

  const [
    deleteFileUpload,
  ] = useMutation<DeleteFileResponse, { mediaId?: string }>(DELETE_FILE_UPLOAD, {
    onCompleted: (data: DeleteFileResponse) => {
      console.log('deleteFileUpload', data.canceledImportMedia);
      const mediaId = data.canceledImportMedia.id;
      deleteMediaById(mediaId);
    }
  });

  const [translateMedia] =
    useMutation<{ translateMedia: ImportedMedia[] }, { mediaId: string, translateCode: string[] }>(TRANSLATE_MEDIA, {
      onCompleted: (data) => {
        console.log("translateMedia", data.translateMedia);
        // const medias = data.translateMedia.map(item => ({ ...item, ...(!item.project_id ? { project_id: projectId } : {}) }));
        // addMedias(medias);
      }
    })

  const [startTranscribe, { data: transcribeMediaData }] =
    useMutation<{ transcribeMedia: JobTranscribe }, { mediaId: string, language: string, dictionaryId?: string }>(TRANSCRIBE_MEDIA, {
      onCompleted: (data) => {
        console.log("startTranscribe", data.transcribeMedia)
        getStatusTranscribe({ variables: { jobId: data.transcribeMedia.id } })
      },
      onError: (err) => {
        console.log("startTranscribe error", err.message)
      }
    })

  const [getStatusTranscribe, { data: dataStatusTrancribe, stopPolling }] =
    useLazyQuery<{ getStatusTranscribe: JobTranscribe }, { jobId: string }>(GET_STATUS_TRANSCRIBE, {
      pollInterval: 5000,
      // fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        console.log("getStatusTranscribe", data.getStatusTranscribe);
        // const { mediaId, status } = data.getStatusTranscribe;
        // updateMediaByMediaId(mediaId, { status });
      }
    });

  const [chargeCreditBefore] = useMutation<
    { chargeCreditMediaBefore: ChargeCreditBefore },
    { mediaId: string; languages: string[]; type: string }
  >(CHARGE_CREDIT_MEDIA_BEFORE, {
    onCompleted: data => {
      console.log('chargeCreditBefore', data.chargeCreditMediaBefore);

      const currentFileImport = listFileImport.find(item => item.id === data.chargeCreditMediaBefore.media?.id)
      const response = data.chargeCreditMediaBefore;
      if (!response || !response.media || !currentFileImport) return;

      updateMediaByMediaId(response.media.id, {
        creditCharged: data.chargeCreditMediaBefore.creditCharged,
        cardId: data.chargeCreditMediaBefore.cardId,
        isCheckCharge: false,
      });

      if (data.chargeCreditMediaBefore.errorStatus) {

        updateMediaByMediaId(response.media.id, {
          isChargeFail: true,
        });

        return;
      }

      if (response.media.status === TranscribeStatus.TRANSCRIBE_PAID) {

        const numIncreasePerSeconds = 100 / ((response.media.durationInSeconds || 0) * 0.5);

        const intervalTranscribe = setInterval(() => {
          if (!response || !response.media) return;

          updateMediaByMediaIdCallback(response.media.id, (media: any) => {
            const tempPercentage = (media.percentage || 0) + numIncreasePerSeconds;

            return {
              ...media,
              percentage: tempPercentage >= 100 ? 100 : tempPercentage,
            }
          });
        }, 1000);

        updateMediaByMediaId(response.media.id, {
          percentage: 0,
          progressText: 'Transcribing',
          interval: intervalTranscribe,
          status: TranscribeStatus.TRANSCRIBING,
        });

        startTranscribe({
          variables: {
            mediaId: response.media?.id,
            language: response.languages[0],
            dictionaryId: currentFileImport.dictionary_id
          },
        });
      } else if (response.media?.status === TranscribeStatus.TRANSLATE_PAID) {
        // setComplete(false);

        if (countTranslate > 1) {
          updateMediaByMediaId(response.media.id, {
            percentage: 0,
            progressText: 'Translating',
            status: TranscribeStatus.TRANSLATING,
          });
        }

        translateMedia({
          variables: {
            mediaId: response.media.id,
            translateCode: response.languages,
          },
        });
      }
    },
  });

  const [retrievedTranscribe, { data: retrievedTranscribeData }] =
    useMutation<{ retrievedTranscribe: ImportedMedia }, { mediaId: string, jobId: string }>(RETRIVED_TRANSCRIBE, {
      onCompleted: (data) => {
        console.log("retrievedTranscribe", data.retrievedTranscribe)
        onTranscribeDone(data.retrievedTranscribe)
      },
      onError: (err) => {
        console.log("retrievedTranscribe error", err.message)
      }
    })

  const [
    updateStatusMediaFail,
  ] = useMutation<{ updateStatusFailUpload: ImportedMedia }, { mediaId?: string }>(UPDATE_STATUS_MEDIA_FAIL, {
    onCompleted: (data) => {
      console.log('updateStatusMediaFail', data.updateStatusFailUpload);
    }
  });

  const isOnline = typeof window !== 'undefined' && navigator.onLine;

  useEffect(() => {
    // console.log("isOnline", isOnline)
    if (!navigator.onLine) {
      listFileImport.map(media => {
        if (media.status === TranscribeStatus.UPLOADING) {
          media.xhr.abort();
          updateMediaByMediaId(media.id, {
            status: TranscribeStatus.FAILED,
          });
        }
      })
    }
  }, [isOnline])

  useEffect(() => {
    //in_queue,transcribing,rejected,done

    if (
      dataStatusTrancribe &&
      dataStatusTrancribe.getStatusTranscribe.status !== "in_queue" &&
      dataStatusTrancribe.getStatusTranscribe.status !== "transcribing" &&
      stopPolling
    ) {
      const tempFileImport = listFileImport.find(fileImport => fileImport.id === dataStatusTrancribe.getStatusTranscribe.mediaId);
      if (!tempFileImport) return;

      clearInterval(tempFileImport.interval);
      onResultTranscribe(dataStatusTrancribe.getStatusTranscribe);
      stopPolling();
      retrievedTranscribe({
        variables: {
          jobId: dataStatusTrancribe.getStatusTranscribe.id,
          mediaId: dataStatusTrancribe.getStatusTranscribe.mediaId
        }
      })
    }
  }, [dataStatusTrancribe, stopPolling])

  function handleLoadFiles(files: FileCloud[]) {
    console.log('files', files);

    files.map(file => {
      const isExistedFileName = listFileImport.some(item => item.name === file.fileName && item.status === TranscribeStatus.UPLOADING && !item.archived);

      if (!isExistedFileName) {
        const media = {
          name: file.fileName,
          progressText: file.type === InputFileEnum.LOCAL ? 'Uploading' : 'Transcoding',
          fileCloud: file,
          project_id: projectId,
          durationInSeconds: file.durationInSeconds,
          percentage: 0,
          status: TranscribeStatus.UPLOADING,
        }

        addToTopMedia(media);

        createMedia({
          variables: {
            filename: file.fileName || '',
            frameFilePath: 'img/poster-video.png',
            projectId: projectId,
          }
        })
      }
    })
  }

  function onSubUploadFile(data: PercentageProcessData) {
    const { percentageProcessResponse, mediaId } = data;
    const media = getMediaById(mediaId);

    if (!media) return;

    let newPercentage = media.percentage || 0;

    if (
      !media.isCancel &&
      percentageProcessResponse > newPercentage
    ) {
      newPercentage = percentageProcessResponse;

      updateMediaByMediaId(mediaId, {
        percentage: newPercentage,
        progressText: 'Transcoding'
      });

      if (media.status !== TranscribeStatus.READY && newPercentage === 100) {
        console.log("refetchProject");
        getAssemblyProject();
      }
    }
  }

  function onSubTranslateFile(data: ProgressTranslateResponse) {
    const { progress, mediaIdTranslate } = data;
    const media = getMediaById(mediaIdTranslate);

    if (progress === 100) {
      console.log("refetchProject");
      getAssemblyProject();
      // updateMediaByMediaId(mediaIdTranslate, {
      //   status: TranscribeStatus.TRANSCRIBED
      // });
    }

    if (!media || countTranslate == 1) return;

    updateMediaByMediaId(mediaIdTranslate, {
      percentage: progress,
      progressText: 'Translating',
      status: TranscribeStatus.TRANSLATING
    });
  }

  function abortUploadFile(media: ImportedMedia) {
    updateMediaByMediaId(media.id, { isCancel: true })
    const tempPercentage = media.percentage;

    if (
      media.xhr &&
      tempPercentage
    ) {
      media.xhr.abort();
    }

    if (media.status === TranscribeStatus.TRANSCRIBED) {
      clearInterval(media.interval)
    }

    deleteFileUpload({
      variables: {
        mediaId: media.id
      }
    });
  }

  function onStartTranscribe(media: ImportedMedia, currentLanguageRegion?: LanguageRegion, dictionaryId?: string) {
    console.log("onStartTranscribe", media);

    if (currentLanguageRegion) {
      setLastTranscribe(currentLanguageRegion);
    }

    updateMediaByMediaId(media.id, {
      // percentage: 0,
      // progressText: 'Transcribing',
      languageRegion: currentLanguageRegion,
      // status: TranscribeStatus.TRANSCRIBING
      dictionary_id: dictionaryId
    });
  }

  function onResultTranscribe(data: JobTranscribe) {
    const { mediaId, status } = data;

    updateMediaByMediaIdCallback(mediaId, (media: any) => ({
      ...media,
      percentage: status === "done" ? 100 : media.percentage,
    }));
  }

  function onTranscribeDone(media: ImportedMedia) {
    updateMediaByMediaId(media.id, {
      status: TranscribeStatus.TRANSCRIBED,
    });
  }

  function setListFileMedia(totalData: ImportedMedia[]) {
    // const sortedMedias = data.sort(media => media.status === TranscribeStatus.UPLOADING ? -1 : 1);
    // console.log('data', totalData.filter(media => !media.archived))
    // console.log('listFileImport', listFileImport)
    const newMedias = totalData
      .filter(media => !media.archived)
      .map(item => {
        const mediaState = getMediaById(item.id);

        if (item.status === TranscribeStatus.TRANSCRIBING && item.durationInSeconds) {
          getStatusTranscribe({ variables: { jobId: item.job_id || '' } });

          const uploadedTime = moment().diff(moment(item.start_time_transcription), 'seconds');
          const tempSecond = item.durationInSeconds / 2;
          let tempPercentage = 100;

          if (tempSecond > uploadedTime) {
            const tempDuration = (tempSecond - uploadedTime) * 100 / tempSecond;
            tempPercentage = 100 - tempDuration;
          }

          return {
            ...item,
            progressText: 'Transcribing',
            percentage: tempPercentage,
          }
        } else if (item.status === TranscribeStatus.UPLOADING && !mediaState) {
          return {
            ...item,
            archived: true,
          }
        } else if (item.status === TranscribeStatus.UPLOADING && mediaState?.status === TranscribeStatus.FAILED) {
          updateStatusMediaFail({ variables: { mediaId: item.id } })
          return {
            ...item,
            ...mediaState,
          }
        } else if (item.status === TranscribeStatus.UPLOADING || item.status === TranscribeStatus.TRANSCODING) {
          return {
            ...item,
            ...(item.percentage === undefined ? { percentage: 0 } : {}),
            ...(item.progressText === undefined ? { progressText: item.status === TranscribeStatus.UPLOADING ? 'Uploading' : 'Transcoding' } : {}),
            ...mediaState,
          }
        } else if (
          (item.status === TranscribeStatus.TRANSCRIBED && mediaState?.status === TranscribeStatus.TRANSCRIBING) ||
          (item.status === TranscribeStatus.READY && mediaState?.status === TranscribeStatus.UPLOADING)
        ) {
          return item;
        } else if (mediaState?.status === TranscribeStatus.TRANSCRIBING) {
          return {
            ...item,
            ...(item.percentage === undefined ? { percentage: 0 } : {}),
            ...(item.progressText === undefined ? { progressText: 'Transcribing' } : {}),
            ...mediaState,
          }
        } else if (item.status === TranscribeStatus.TRANSLATING ||
          (item.status === TranscribeStatus.TRANSLATE_PAID && mediaState?.status === TranscribeStatus.TRANSLATING)
        ) {
          return {
            ...item,
            ...(item.percentage === undefined ? { percentage: 0 } : {}),
            ...(item.progressText === undefined ? { progressText: 'Translating' } : {}),
          }
        }

        return item;
      })

    listFileImport.filter(item => !item.archived).map(item => {
      if (!totalData.some(media => media.id === item.id) || !item.id) {
        newMedias.push(item);
      }
    })

    // refresh page 
    if (listFileImport.length === 0) {
      newMedias.map(item => {
        if (item.status === TranscribeStatus.UPLOADING) {
          deleteFileUpload({
            variables: {
              mediaId: item.id
            }
          });
        } else if (item.status === TranscribeStatus.TRANSCODING) {
          updateMediaByMediaId(item.id, {
            progressText: 'Transcoding',
            percentage: 0,
          });
        } else if (item.status === TranscribeStatus.TRANSCRIBING && item.durationInSeconds) {
          const numIncreasePerSeconds = 100 / ((item.durationInSeconds || 0) * 0.5);

          const intervalTranscribe = setInterval(() => {

            updateMediaByMediaIdCallback(item.id, (media: any) => {
              const tempPercentage = (media.percentage || 0) + numIncreasePerSeconds;

              return {
                ...media,
                percentage: tempPercentage >= 100 ? 100 : tempPercentage,
              }
            });
          }, 1000);

          updateMediaByMediaId(item.id, {
            progressText: 'Transcribing',
            interval: intervalTranscribe,
            status: TranscribeStatus.TRANSCRIBING,
          });
        }
      })
      // console.log('newtempList', newMedias.filter(media => media.status !== TranscribeStatus.UPLOADING));
      setListFileImport(newMedias.filter(media => media.status !== TranscribeStatus.UPLOADING))
    } else {
      // console.log('tempList', newMedias);
      // setListFileImport(newMedias.sort(media => media.status === TranscribeStatus.UPLOADING ? -1 : 1));
      const listFileUploading = newMedias.filter(media => media.status === TranscribeStatus.UPLOADING || media.status === TranscribeStatus.TRANSCODING)
                                          .sort(media => media.status === TranscribeStatus.UPLOADING ? -1 : 1)
      const listFileOther = newMedias.filter(media => media.status !== TranscribeStatus.UPLOADING && media.status !== TranscribeStatus.TRANSCODING)

      setListFileImport([...listFileUploading, ...listFileOther]);
    }
  }

  function onStartTranslate(media: ImportedMedia, listTranslate: Language[]) {
    // const listCode = [] as string[];

    // listTranslate.map(language => {
    //   if (!language.translation_code) return;

    //   listCode.push(language.translation_code)
    // });

    // translateMedia({ variables: { mediaId: media.id, translateCode: listCode } })
  }

  function onChargeCreditBefore(media: ImportedMedia, languages: string[], type: string) {

    if (type == "TRANSLATE") {
      setCountTranslate(languages.length);
    }

    updateMediaByMediaId(media.id, {
      languages: languages,
      typeJob: type,
      isCheckCharge: true,
    });

    chargeCreditBefore({
      variables: {
        mediaId: media.id,
        languages: languages,
        type: type
      }
    });
  }

  function addMedias(medias: ImportedMedia[] = []) {
    setListFileImport((preState: any) => [...preState, ...medias]);
  }

  function addMedia(media = {}) {
    setListFileImport((preState: any) => preState.concat(media));
  }

  function addToTopMedia(media = {}) {
    setListFileImport((preState: any) => [media, ...preState]);
  }

  function updateMediaAtIdx(idx: number, properties = {}) {
    setListFileImport((preState: any) => {
      const newState = [...preState];
      if (!newState[idx]) return newState;

      newState[idx] = {
        ...newState[idx],
        ...properties
      };

      return newState;
    });
  }

  function updateMediaByMediaId(mediaId: string, properties = {}) {
    setListFileImport((preState: any) => {
      const newState = [...preState];
      const idx = newState.findIndex(item => item.id === mediaId);

      if (idx === -1) return newState;

      newState[idx] = {
        ...newState[idx],
        ...properties
      };

      return newState;
    });
  }

  function updateMediaByMediaIdCallback(mediaId: string, callback: Function) {
    setListFileImport((preState: any) => {
      const newState = [...preState];
      const idx = newState.findIndex(item => item.id === mediaId);
      if (idx === -1) return newState;

      newState[idx] = callback(newState[idx]);
      return newState;
    });
  }

  function getMediaById(mediaId: string) {
    return listFileImport.find(item => item.id === mediaId);
  }

  function deleteMediaById(mediaId: string) {
    setListFileImport(prev => prev.filter(item => item.id !== mediaId));
  }

  function onCloseModalInsufficientCredit(media: ImportedMedia) {
    updateMediaByMediaId(media.id, {
      isChargeFail: false,
      isCheckCharge: false,
    });
  }

  function onAmendMedia(mediaId: string, folderId: string | null) {
    updateMediaByMediaId(mediaId, { project_folder_id: folderId })
  }

  return (
    <Provider value={{
      listFileImport,
      lastTranscibe,
      setListFileMedia,
      setProjectId,
      setAccessTokenGD,
      setLastTranscribe,
      handleLoadFiles,
      onStartTranslate,
      onTranscribeDone,
      onChargeCreditBefore,
      onStartTranscribe,
      onResultTranscribe,
      onSubUploadFile,
      abortUploadFile,
      onCloseModalInsufficientCredit,
      onAmendMedia,
      onSubTranslateFile,
    }}>{children}</Provider>
  );
}

export { sideBarStore, SideBarProvider };
